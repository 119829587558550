export interface IComponentPropertyGroup {
  id: string;
  name: string;
}

export interface IComponentProperty<T = any> {
  // Уникальное наименование свойства (пример применения: component.properties[id] = defaultValue())
  id: string;
  // Наименование (надпись)
  name: string;
  // Подсказка (описание свойства)
  description: string | null;
  // Иконка свойства
  icon: string | null;
  // Группа (вкладка) свойства "Основное", "Дизайн" или "Поведение"
  group: 'general' | 'design' | 'behavior';
  // Может определить значения по умолчанию
  defaultValue?: (() => T) | T;
  // Vue компонент, через который редактируется свойство
  vueComponent: string;
  // Указанному компоненту можно передать некоторые аргументы
  vueProps: {
    [prop: string]: any;
  }
}

export const componentProperties: IComponentProperty[] = []

export const componentPropertyGroups: IComponentPropertyGroup[] = [
  { id: 'general', name: 'interface_editor.general' },
  { id: 'design', name: 'interface_editor.design' },
  { id: 'behavior', name: 'interface_editor.behavior' }
]
